@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom CSS */
.hide-scrollbar {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
}

.hide-scrollbar::-webkit-scrollbar {
    display: none; /* Chrome, Safari, and Opera */
}
